/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import config from '../../../config';

import faviconIco from '../../images/favicon.ico';
import faviconSvg from '../../images/favicon.svg';
import faviconPng01 from '../../images/android-chrome-192x192.png';
import faviconPng02 from '../../images/apple-touch-icon.png';
import { getGtmDefaultConsentConfigSnippet, getGtmScriptSnippet } from '../../../../utls/gtm';

type Props = {
  title: string;
  lang?: string;
  meta?: { name: string; content: string }[];
  description?: string;
};

const SEO = ({ description = '', lang = 'en', meta = [], title }: Props): JSX.Element => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const isCsr = typeof window === 'object';
  if (isCsr) {
    // CSR時にtitleの翻訳がうまくいかないため、SSR時の状態に留める
    return <></>;
  }
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        {
          rel: 'stylesheet',
          href: 'https://use.fontawesome.com/releases/v5.6.1/css/all.css'
        },
        {
          rel: 'shortcut icon',
          href: faviconIco,
          type: 'image/x-icon'
        },
        {
          rel: 'icon',
          href: faviconSvg,
          type: 'image/svg+xml'
        },
        {
          rel: 'icon alternate',
          href: faviconPng01,
          type: 'image/png'
        },
        {
          rel: 'apple-touch-icon',
          href: faviconPng02,
          type: 'image/png'
        }
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:url`,
          content: 'https://www.worldshopping.global/'
        },
        {
          property: `og:site_name`,
          content: `${site.siteMetadata.title}`
        },
        {
          property: `og:image`,
          content: 'https://www.worldshopping.global/images/ogp_wsg.png'
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      <script
        id="locizify"
        // @ts-expect-error
        projectId={config.locize.projectId}
        apiKey={config.locize.apiKey}
        version={config.locize.version}
        referencelng={config.locize.referenceLng}
        fallbacklng={config.locize.fallbackLng}
        keyAttr="i18next-key"
        ignoreWithoutKey="true"
        src="https://unpkg.com/locizify@^2.0.0"
      />
      <script src="https://apps.elfsight.com/p/platform.js" />
      {getGtmDefaultConsentConfigSnippet()}
      {getGtmScriptSnippet()}
    </Helmet>
  );
};

export default SEO;
