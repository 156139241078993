import React from 'react';

export function getGtmDefaultConsentConfigSnippet(): JSX.Element {
  return (
    <script>
      {`
          window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} 
          gtag('consent', 'default', {  
            ad_storage: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "denied",
            wait_for_update: 500
          });
        `}
    </script>
  );
}

export function getGtmScriptSnippet(): JSX.Element {
  const devScript = `  
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=bgTq_E6OsBpEW_xsAgGMMQ&gtm_preview=env-7&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-M42L5TK');
  `;

  const prodScript = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-M42L5TK');
  `;

  return <script>{process.env.GATSBY_STAGE === 'prod' ? prodScript : devScript}</script>;
}

export function getGtmNoScriptSnippet(): JSX.Element {
  const devEnvSrc = 'https://www.googletagmanager.com/ns.html?id=GTM-M42L5TK&gtm_auth=bgTq_E6OsBpEW_xsAgGMMQ&gtm_preview=env-7&gtm_cookies_win=x';
  const prodEnvSec = 'https://www.googletagmanager.com/ns.html?id=GTM-M42L5TK';

  return (
    <noscript key="gtm-no-script">
      <iframe src={process.env.GATSBY_STAGE === 'prod' ? prodEnvSec : devEnvSrc} height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
    </noscript>
  );
}
