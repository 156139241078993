import React from 'react';
import { useTranslation } from 'react-i18next';

import Supporter01 from '../../images/support-img01.png';
import Supporter02 from '../../images/support-img02.png';
import Supporter03 from '../../images/support-img03.png';
import Supporter04 from '../../images/support-img04.png';

const Customer = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <div className="support">
        <div className="container">
          <ul>
            <li>
              <img src={Supporter01} alt="" />
            </li>
            <li>
              <img src={Supporter02} alt="" />
            </li>
            <li>
              <img src={Supporter03} alt="" />
            </li>
            <li>
              <img src={Supporter04} alt="" />
            </li>
          </ul>
          <div>
            <div className="support__description">
              <h2 i18next-key="import-customer-title">{t('import-customer-title')}</h2>
              <p i18next-key="import-customer-text">{t('import-customer-text')}</p>
              <p className="strong" i18next-key="import-customer-text-lang">
                {t('import-customer-text-lang')}
              </p>
            </div>
            <div className="support__enter">
              <a href="https://{{link-ws-help-url}}" className="btn fas fa-chevron-right">
                <span i18next-key="link-ws-help-text">{t('link-ws-help-text')}</span>
              </a>
              <a href="/guide" className="link-flat fas fa-chevron-circle-right">
                <span i18next-key="link-ws-guide-text">{t('link-ws-guide-text')}</span>
              </a>
              <a href="https://{{link-help-faq-url}}" className="link-flat fas fa-chevron-circle-right">
                <span i18next-key="link-help-faq-text">{t('link-help-faq-text')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="purchaserequest">
        <div className="container">
          <div className="container-inner">
            <h3 i18next-key="import-purchaserequest-title">{t('import-purchaserequest-title')}</h3>
            <p i18next-key="import-purchaserequest-text">{t('import-purchaserequest-text')}</p>
            <div className="enter">
              <a href="https://{{link-ws-purchaserequest-url}}" target="_blank" rel="noopener noreferrer" className="btn-outline fas fa-chevron-right">
                <span i18next-key="import-purchaserequest-btn">{t('import-purchaserequest-btn')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Customer;
