const STAGE = process.env.GATSBY_STAGE;
const connectionSettingsPath = STAGE === 'prod' ? '/sfdc/prod/api' : '/sfdc/dev/dev';
const courierCalculator = STAGE === 'prod' ? 'https://courier-api.worldshopping.global' : 'https://courier-api-dev.worldshopping.global';
const geoLocation = STAGE === 'prod' ? 'https://checkout-api.worldshopping.jp/v1/geolocation' : 'https://checkout-api-dev.worldshopping.jp/dev/geolocation';

export default {
  api: {
    courierCalculator,
    geoLocation
  },
  locize: {
    projectId: 'c0beb182-9903-446c-8e25-bd099038a5a7',
    apiKey: 'd5ff43a0-26b8-4c11-8e3b-acadeda1f546',
    version: 'latest',
    ns: 'translation',
    referenceLng: 'en',
    fallbackLng: 'en'
  },
  sfdc: {
    connectionSettingsPath
  }
};
