import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ResourceBundles } from '../../server/infrastructure/language-service';

import config from '../../config';

/**
 * 必ずトップレベルのコンポーネントで一度実行する必要がある
 * NOTE: TFunctionを渡す形も試したがpageCreatorからコンポーネントに関数を正しく渡せないためResourceBundleを渡して再初期化している
 */
export function initI18nextWithResourceBundles(resourceBundles: ResourceBundles): void {
  i18next.use(initReactI18next).init({
    resources: resourceBundles,
    ns: [config.locize.ns],
    defaultNS: config.locize.ns,
    fallbackLng: config.locize.fallbackLng,
    interpolation: {
      escapeValue: false
    }
  });
}
