import React from 'react';
import { useTranslation } from 'react-i18next';

import SwLogo from '../../images/icon-ws-logo.svg';
import ZzLogo from '../../images/icon-zz-logo.svg';
import IconTwitter from '../../images/icon-twitter.svg';
import IconPinterest from '../../images/icon-pinterest.svg';
import ImageFacebook from '../../images/icon-facebook.png';

const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="backtotop">
        <a href="#" className="fas fa-arrow-up" />
      </div>

      <div className="footer__ws-menu">
        <div className="footer__ws-menu-list">
          <div>
            <div className="footer__menu-title">
              <span i18next-key="footer-menu-title-guide">{t('footer-menu-title-guide')}</span>
            </div>
            <ul>
              <li>
                <a href="/guide">
                  <span i18next-key="link-ws-guide-text">{t('link-ws-guide-text')}</span>
                </a>
              </li>
              <li>
                <a href="https://{{link-help-purchase-request-url}}">
                  <span i18next-key="link-help-purchase-request-text">{t('link-help-purchase-request-text')}</span>
                </a>
              </li>
              <li>
                <a href="https://{{link-help-payment-method-url}}">
                  <span i18next-key="link-help-payment-method-text">{t('link-help-payment-method-text')}</span>
                </a>
              </li>
              <li>
                <a href="https://{{link-help-url}}">
                  <span i18next-key="link-help-text">{t('link-help-text')}</span>
                </a>
              </li>
              <li>
                <a href="https://{{link-help-faq-url}}">
                  <span i18next-key="link-help-faq-text">{t('link-help-faq-text')}</span>
                </a>
              </li>
            </ul>
          </div>
          <div>
            <div className="footer__menu-title">
              <span i18next-key="footer-menu-title-international-shipping">{t('footer-menu-title-international-shipping')}</span>
            </div>
            <ul>
              <li>
                <a href="https://{{link-help-international-shipping-url}}">
                  <span i18next-key="link-help-international-shipping-text">{t('link-help-international-shipping-text')}</span>
                </a>
              </li>
              <li>
                <a href="https://{{link-help-shipping-method-url}}">
                  <span i18next-key="link-help-shipping-method-text">{t('link-help-shipping-method-text')}</span>
                </a>
              </li>
              <li>
                <a href="/simulator">
                  <span i18next-key="link-help-shipping-calculator-text">{t('link-help-shipping-calculator-text')}</span>
                </a>
              </li>
              <li>
                <a href="https://{{link-help-correspondence-country-url}}">
                  <span i18next-key="link-help-correspondence-country-text">{t('link-help-correspondence-country-text')}</span>
                </a>
              </li>
            </ul>
          </div>
          <div>
            <div className="footer__menu-title">
              <span i18next-key="footer-menu-title-notes">{t('footer-menu-title-notes')}</span>
            </div>
            <ul>
              <li>
                <a href="https://{{link-help-notes-url}}">
                  <span i18next-key="link-help-notes-text">{t('link-help-notes-text')}</span>
                </a>
              </li>
              <li>
                <a href="https://{{link-help-prohibited-goods-url}}">
                  <span i18next-key="link-help-prohibited-goods-text">{t('link-help-prohibited-goods-text')}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer__ws-menu-header">
          <a href="/">
            <img src={SwLogo} height="40" width="200" />
          </a>
          <div className="footer__ws-menu-header-sns">
            <div>
              <a href="https://{{link-facebook-url}}" target="_blank" rel="noopener noreferrer">
                <img src={ImageFacebook} height="86" width="335" alt="" />
              </a>
            </div>
            <div>
              <ul>
                {/*
                <li>
                  <a href="https://www.facebook.com/groups/welovejfashion/" target="_blank">
                    <img src={IconFacebook} height="32" width="32" alt="" />
                  </a>
                </li>
                */}
                <li>
                  <a href="https://{{link-twitter-url}}" target="_blank" rel="noopener noreferrer">
                    <img src={IconTwitter} height="32" width="32" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://{{link-pinterest-url}}" target="_blank" rel="noopener noreferrer">
                    <img src={IconPinterest} height="32" width="32" alt="" />
                  </a>
                </li>
              </ul>
              <p>Stories &amp; Highlights from Japan</p>
            </div>
          </div>
          {/*
          <a href="http://{{footer-menu-blog-url}}" target="_blank">
           <span i18next-key="footer-menu-blog-text">{t("footer-menu-blog-text")}</span>
          </a>
          */}
        </div>
      </div>

      <div className="footer__zz-menu">
        <div>
          <a href="https://{{link-zig-zag-url}}" target="_blank" rel="noopener noreferrer">
            <img src={ZzLogo} height="40" width="200" alt="" />
          </a>
        </div>
        <ul>
          <li>
            <a href="https://{{link-help-aboutus-url}}">
              <span i18next-key="link-help-aboutus-text">{t('link-help-aboutus-text')}</span>
            </a>
          </li>
          <li>
            <a href="https://{{link-help-terms-url}}">
              <span i18next-key="link-help-terms-text">{t('link-help-terms-text')}</span>
            </a>
          </li>
          <li>
            <a href="https://{{link-help-privacy-policy-url}}">
              <span i18next-key="link-help-privacy-policy-text">{t('link-help-privacy-policy-text')}</span>
            </a>
          </li>
          <li>
            <a href="https://{{link-help-the-specified-commerical-transactions-law-url}}">
              <span i18next-key="link-help-the-specified-commerical-transactions-law-text">{t('link-help-the-specified-commerical-transactions-law-text')}</span>
            </a>
          </li>
          <li>
            <a href="https://{{link-help-contactus-antique-url}}">
              <span i18next-key="link-help-contactus-antique-text">{t('link-help-contactus-antique-text')}</span>
            </a>
          </li>
          <li>
            <a href="https://{{link-help-contactus-url}}">
              <span i18next-key="link-help-contactus-text">{t('link-help-contactus-text')}</span>
            </a>
          </li>
        </ul>
        <p className="footer__copywright">© 2020 zig-zag Inc.</p>
      </div>
    </footer>
  );
};
export default Footer;
